import { graphql } from 'gatsby'
import React from 'react'

//
import Svg404 from '../../static/images/404.svg'
import Layout from '../components/layout'
import { Hero, ButtonNew } from '../storybook'
import { BUTTON_VARIANT } from '../storybook/ButtonNew/ButtonNew'
import { ImageFluid } from '../utils/image'
import { getMicroCopy } from '../utils/microcopy'
import { buildPageMetaTags } from '../utils/seo'
import { renderRichTextLine } from '../utils/text'

const validLocales = { en: 'en', de: 'de', fr: 'fr', es: 'es', us: 'en-US' }

const buildLangSlugs = (marketId, markets, slug) => {
  const slugs = {}

  markets.group.forEach((group) => {
    group.edges.forEach((edge) => {
      if (
        edge.node?.enabledLanguages?.includes(edge.node?.locale) &&
        edge.node.contentfulId === marketId
      ) {
        slugs[edge.node.locale] = `/${edge.node.slugPrefix}/${slug}`.replace(/\/\//g, '/')
      }
    })
  })

  return slugs
}

const buildMarketsSlugs = (locale, markets, slug, defaultMarketsSlugs) => {
  const slugs = {}
  markets.group.forEach((group) => {
    group.edges
      .map((edge) => edge.node)
      .filter((node) => node.locale === locale && node.slugPrefix)
      .forEach((node) => {
        if (node?.enabledLanguages?.includes(locale)) {
          slugs[node.title] = `/${node.slugPrefix}/${slug}`.replace(/\/\//g, '/')
        } else {
          if (defaultMarketsSlugs[node.title]) {
            const defaultMarket = defaultMarketsSlugs[node.title]
            slugs[node.title] = `/${defaultMarket.slugPrefix}/${slug}`.replace(/\/\//g, '/')
          }
        }
      })
  })
  return slugs
}

const NotFoundPage = ({ location, data }) => {
  const {
    site404,
    allContentfulMicrocopySet,
    allContentfulSettings,
    allContentfulMarket,
    allMarketsByCode,
    allMarketsByContentfulId
  } = data

  const { siteMetadata } = site404

  const slugPrefix = location?.slugPrefix.replace(/\//g, '') || ''
  let locale = slugPrefix.split('-')[0] || 'en'
  if (!validLocales[locale]) {
    locale = 'en'
  }

  const pageSlug = location.pathname.replace(location?.slugPrefix, '') || ''

  const isCareersPage = pageSlug.startsWith('careers/')

  const allMarkets = {}

  allMarketsByCode.group.forEach((group) => {
    allMarkets[group.isoCode] = {}
    group.edges
      .map((edge) => edge.node)
      .forEach((node) => {
        allMarkets[group.isoCode][node.locale] = { ...node }
      })
  })

  const defaultMarketsSlugs = {}
  allMarketsByContentfulId.group.forEach((group) => {
    group.edges
      .map((edge) => edge.node)
      .forEach((node) => {
        if (node?.enabledLanguages?.includes(node.locale) && node.locale === node.defaultLanguage) {
          defaultMarketsSlugs[node.title] = { locale: node.locale, slugPrefix: node.slugPrefix }
        }
      })
  })

  const microCopyData = {}
  allContentfulMicrocopySet.group
    .find((group) => group.locale === locale)
    .edges.map((edge) => edge.node)
    .forEach((node) => {
      node.values.forEach((value) => {
        microCopyData[`${node.key}.${value.key}`] = { value: value.value }
      })
    })

  let market = allContentfulMarket.edges
    .map((edge) => edge.node)
    .find((node) => node.slugPrefix === slugPrefix)

  if (!market) {
    market = allContentfulMarket.edges
      .map((edge) => edge.node)
      .find((node) => node.slugPrefix === locale)
  }

  const settings = allContentfulSettings.group
    .find((group) => group.locale === locale)
    .edges.map((edge) => edge.node)[0]

  const langSlugs = buildLangSlugs(market.contentfulId, allMarketsByContentfulId, pageSlug)
  const marketsSlugs = buildMarketsSlugs(
    locale,
    allMarketsByContentfulId,
    pageSlug,
    defaultMarketsSlugs
  )
  const { careersHeader } = settings

  const metatags = buildPageMetaTags(
    {
      title: '404',
      metaTags: { nofollow: true, noindex: true, noarchive: true, nosnippet: true, title: '404' },
      pageType: 'page404'
    },
    siteMetadata
  )

  return (
    <Layout
      metaTags={metatags}
      marketsSlugs={marketsSlugs}
      allMarkets={allMarkets}
      langSlugs={langSlugs}
      header={isCareersPage ? careersHeader : market.header}
      footerMainNavigation={market.footer?.mainNavigation}
      footerSocialMedia={market.footer?.socialMedia}
      footerLegalNavigation={market.footer?.legalNavigation}
      footerActions={market.footer?.actions}
      marketPhoneNo={market.phoneNumber}
      marketPhoneNo2={market.phoneNumber2}
      siteUrl={siteMetadata.siteUrl || '/'}
      countrySwitcher={false}
      minimalFooter={isCareersPage}
    >
      <div className="u-text-align:center u-margin-top:40">
        <ImageFluid
          image={{
            url: Svg404
          }}
          width={300}
          height={200}
          containImages
        />
      </div>
      <div className="u-text-align:center u-margin-top:24 u-margin-bottom:64">
        <Hero
          noVerticalPadding
          title={getMicroCopy({ key: 'global.title404', data: microCopyData })}
          content={renderRichTextLine(getMicroCopy({ key: 'global.text404', data: microCopyData }))}
        >
          <ButtonNew variant={BUTTON_VARIANT.PRIMARY} size="md" url={`/${slugPrefix}`}>
            {getMicroCopy({ key: 'global.ctaLabel404', data: microCopyData })}
          </ButtonNew>
        </Hero>
      </div>
    </Layout>
  )
}

export const notFoundQuery = graphql`
  fragment FragmentFooter on ContentfulFooter {
    id
    contentful_id
    mainNavigation {
      id
      title
      navigationItems {
        ...FragmentNavigationItem
      }
      column
    }
    legalNavigation {
      ...FragmentNavigationItem
    }
    socialMedia {
      ...FragmentNavigationItem
    }
    actions {
      ...FragmentNavigationItem
    }
  }
  fragment FragmentMicroCopy on ContentfulMicrocopySet {
    key
    values {
      key
      value
    }
  }
  query {
    site404: site {
      siteMetadata {
        siteName
        siteUrl
      }
    }

    allMarketsByCode: allContentfulMarket {
      group(field: countryIsoCode) {
        isoCode: fieldValue
        edges {
          node {
            title
            locale: node_locale
            slugPrefix
          }
        }
      }
    }

    allMarketsByContentfulId: allContentfulMarket {
      group(field: contentful_id) {
        edges {
          node {
            id
            contentfulId: contentful_id
            locale: node_locale
            title
            slugPrefix
            enabledLanguages
            defaultLanguage
          }
        }
      }
    }

    allContentfulMarket {
      edges {
        node {
          id
          contentfulId: contentful_id
          locale: node_locale
          slugPrefix
          countryIsoCode
          title
          svgFlag {
            file {
              url
            }
          }
          phoneNumber
          phoneNumber2
          footer {
            ...FragmentFooter
          }
          header {
            primaryAction {
              ...FragmentNavigationItem
            }
            secondaryAction {
              ...FragmentNavigationItem
            }
            mainNavigation {
              id
              title
              navigationItem {
                ...FragmentNavigationItem
              }
              dropdown {
                id
                contentBlocks {
                  __typename
                  ... on ContentfulHeaderContentBlock {
                    id
                    title
                    content {
                      raw
                    }
                    action {
                      ...FragmentNavigationItem
                    }
                    variant
                  }
                  ... on ContentfulHeaderNavigationBlock {
                    id
                    title
                    navigationItems {
                      ...FragmentNavigationItem
                      icon
                    }
                  }
                }
                dropdownBottomText {
                  raw
                }
              }
            }
          }
        }
      }
    }

    allContentfulMicrocopySet {
      group(field: node_locale) {
        locale: fieldValue
        edges {
          node {
            ...FragmentMicroCopy
          }
        }
      }
    }

    allContentfulSettings {
      group(field: node_locale) {
        locale: fieldValue
        edges {
          node {
            siteTitle
            siteDescription {
              siteDescription
            }
            careersHeader {
              primaryAction {
                ...FragmentNavigationItem
              }
              secondaryAction {
                ...FragmentNavigationItem
              }
              mainNavigation {
                id
                title
                navigationItem {
                  ...FragmentNavigationItem
                }
                dropdown {
                  id
                  contentBlocks {
                    __typename
                    ... on ContentfulHeaderContentBlock {
                      id
                      title
                      content {
                        raw
                      }
                      action {
                        ...FragmentNavigationItem
                      }
                      variant
                    }
                    ... on ContentfulHeaderNavigationBlock {
                      id
                      title
                      navigationItems {
                        ...FragmentNavigationItem
                        icon
                      }
                    }
                  }
                  dropdownBottomText {
                    raw
                    references {
                      ... on ContentfulPage {
                        contentful_id
                        slug
                        __typename
                      }
                      ... on ContentfulIntegration {
                        contentful_id
                        slug
                        __typename
                      }
                      ... on ContentfulCustomer {
                        contentful_id
                        slug
                        __typename
                      }
                    }
                  }
                }
              }
            }
            integrationDetailBenefitBlocks {
              ...FragmentSimpleBlock
            }
            integrationDetailGetStartedBlock {
              ...FragmentSimpleBlock
            }
            integrationDetailRequestDemoBlock {
              ...FragmentSimpleBlock
            }
            requestYourIntegrationPage {
              id
              slug
            }
            requestADemoPage {
              id
              slug
            }
          }
        }
      }
    }
  }
`

export default NotFoundPage
